/* src/pages/Welcome/Welcome.module.css */

.welcomeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: #000000;
    color: white;
    box-sizing: border-box;
}

.welcomeHeader {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 24px;
}

.welcomeLogoContainer {
    margin-right: 20px; /* Adds space between the logo and the slogans */
}

.welcomeLogo {
    width: 120px; /* Adjust size as needed */
    height: auto;
}

.welcomeSloganContainer {
    text-align: center;
}

.welcomeSlogan {
    color: #f60;
    font-size: 32px;
    font-weight: bold;
    margin: 0;
}

h2.welcomeSlogan {
    color: white;
    font-size: 24px;
}

.welcomePageHeader {
    font-size: 32px;
    color: #f60;
    margin: 20px 0;
    text-transform: uppercase;
}

.welcomePageDescription {
    font-size: 18px;
    margin-bottom: 35px;
}

.welcomeContentWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;
}

.welcomeContent {
    flex: 3;
    padding: 20px 20px;
    background-color: #ededee59;
    border-radius: 10px;
    border: #f60 1px solid;
    margin-left: 10px;
}

.welcomeMessage{
    font-size: 24px;
    color: #f60;
}

.welcomeDetails{
    padding: 20px;
}

.welcomeContactsWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
}

.welcomeContacts {
    background-color: #ededee59;
    border-radius: 10px;
    border: #f60 1px solid;
    padding: 10px;
    height: 100%;
}

.welcomeContact {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.welcomeContactIconWrapper {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.welcomeContactIcon {
    width: 100%;
    height: auto;
}

.welcomeContactLink {
    font-size: 16px;
    font-weight: bold;
}

.welcomeContactLink a {
    color: white;
}

.welcomeBanner {
    width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .welcomeHeader, .welcomeContentWrapper {
        flex-direction: column;
    }

    .welcomeLogoContainer, .welcomeSloganContainer {
        text-align: center;
    }

    .welcomeLogo {
        margin-bottom: 10px;
    }
}
